import { useInputs } from "@src/hooks/useInputs";

import { EApiPath } from "@src/api/fetchRecords";
import { useApi } from "@src/api/useApi";
import { ApiCommercialIncentive, IApiCommercialIncentive } from "@src/types/incentives";
import { isEmpty } from "lodash";
import startCase from "lodash/startCase";
import { useMemo } from "react";

const transformer = (incentive: ApiCommercialIncentive): IApiCommercialIncentive => ({
  ...incentive,
  status: startCase(incentive.current_funding_status) as IApiCommercialIncentive["status"],
  eligible_organizations: incentive.eligible_organizations.map((str: string) =>
    startCase(str),
  ) as IApiCommercialIncentive["eligible_organizations"],
  is_eligible_to_specified_form_factors_only: isEmpty(incentive.is_eligible_to_specified_form_factors_only)
    ? ["All"]
    : (incentive.is_eligible_to_specified_form_factors_only.map((str) =>
        // @ts-expect-error - "n/a" is not part of the enum values
        str === "n/a" ? "All" : startCase(str),
      ) as IApiCommercialIncentive["is_eligible_to_specified_form_factors_only"]),
  typical_amount: incentive.typical_amount_description,
});

export default function useCommercialIncentives(skipinitialFetch?: boolean) {
  const { zipCode } = useInputs();

  const options = useMemo(
    () => ({
      skipinitialFetch,
      skipRefresh: skipinitialFetch,
      params: {
        postcode: zipCode,
        project_type: "fleets",
      },
    }),
    [skipinitialFetch, zipCode],
  );
  return useApi(EApiPath.COMMERCIAL_INCENTIVES, transformer, options);
}
