import { IVehicleSet } from "@src/types/sets";
import { createContext } from "react";

interface IVehicleSetContext {
  selectedVehicleSet: IVehicleSet | null;
  setSelectedVehicleSet: React.Dispatch<React.SetStateAction<IVehicleSet | null>>;
}

export const VehicleSetCacheContext = createContext<IVehicleSetContext>({
  selectedVehicleSet: null,
  setSelectedVehicleSet: () => null,
});
