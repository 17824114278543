import { CardLinkProps } from "react-bootstrap";
import ReactGA from "react-ga4";

interface IProps extends CardLinkProps {
  event: string;
  category?: string;
}

const TrackableLink = ({ children, event, category = "Clicked Link", target, href, ...linkProps }: IProps) => {
  const sendEvent = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    ReactGA.event({
      category,
      action: event,
    });

    if (target === "_blank") {
      window.open(href, target);
    } else if (href) {
      window.location.href = href;
    }
  };

  return (
    <a {...linkProps} href={href} onClick={sendEvent} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default TrackableLink;
