import A from "@src/data/ASSUMPTIONS";
import loadProfiles from "@src/data/loadProfiles";
import { TChargingFacilityType } from "@src/types/charging";
import { TTimeRange } from "@src/types/datetime";
import { IChargerSet } from "@src/types/sets";
import { getHoursOfWeek, getHoursOfYear } from "@src/utils/time";
import TIME_PERIODS from "@src/utils/timePeriods";
import { times } from "lodash";

const isWorkday = ({
  chargerSet: { workdays, workmonths },
  date,
  ignoreMonths,
}: {
  chargerSet: IChargerSet;
  date: { dayOfWeek: number; month?: number };
  ignoreMonths: boolean;
}) => workdays.includes(date.dayOfWeek) && (ignoreMonths || (date.month && workmonths.includes(date.month)));

const calcPresetFacilityDailyLoadProfile = (
  chargerSet: IChargerSet,
  { facilityType }: { facilityType: TChargingFacilityType },
) => {
  const dailyLoadProfile = loadProfiles[facilityType];

  const chargerKw = chargerSet.charger?.portKw ?? 0;
  const numChargers = chargerSet.numChargersInstalled;

  // for each hour, get relative weight of that hour's kW to the max value of the 24 hours, multiply by charger count * charger kW
  const maxDailyValue = Math.max(...dailyLoadProfile);
  const scaledDailyLoadProfile = dailyLoadProfile.map(
    (value) => (value / (maxDailyValue || 1)) * chargerKw * numChargers,
  );

  return scaledDailyLoadProfile;
};

const calcCustomFacilityDailyLoadProfile = (chargerSet: IChargerSet) => {
  const { chargersPerTimePeriod, charger } = chargerSet;

  // for each hour, multiply number of chargers used in that hour's time period * charger kW
  return times(24, (hour) => {
    const timePeriod = TIME_PERIODS.find((period) => period.hours[hour] === 1);
    if (timePeriod) {
      const count = chargersPerTimePeriod[timePeriod.key as KeyOf<IChargerSet["chargersPerTimePeriod"]>] || 0;
      return charger?.portKw ?? 0 * count;
    }
    return 0;
  });
};

const calcChargerSetLoadProfile = (
  chargerSet: IChargerSet,
  { timeRange, facilityType }: { timeRange: TTimeRange; facilityType: TChargingFacilityType },
) => {
  const dailyLoadProfile =
    facilityType === "Custom"
      ? calcCustomFacilityDailyLoadProfile(chargerSet)
      : calcPresetFacilityDailyLoadProfile(chargerSet, { facilityType });

  const hours: { dayOfWeek: number; hourStart: number; month?: number }[] =
    timeRange === "yearly" ? getHoursOfYear(A.YEAR) : getHoursOfWeek();

  // apply daily load profile to a full week or year, replacing values with 0 if not a workday
  return hours.map((date) =>
    isWorkday({ chargerSet, date, ignoreMonths: timeRange === "weekly" }) ? dailyLoadProfile[date.hourStart] : 0,
  );
};

export default calcChargerSetLoadProfile;
