import logo from "@src/assets/images/sce-logo.svg";
import { Link } from "@src/layout/Links";
import { memo } from "react";
import { Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";

interface IProps {
  page: string;
}

const Header: React.FC<IProps> = memo(({ page: _page }) => {
  const color = (path: string) => (window.location.pathname === path ? "text-success" : "text-black");

  return (
    <>
      <Navbar expand="lg" className="d-none d-lg-flex justify-content-between bg-body-tertiary py-0 w-100 d-print-none">
        <div className="col-3">
          <Navbar.Brand href="/" className="py-0">
            <img src={logo} alt="The SCE Logo" className="bg-yellow p-3" />
          </Navbar.Brand>
        </div>
        <div className="col-9">
          <Navbar.Toggle aria-controls="header-navbar-nav" />
          <Navbar.Collapse id="header-navbar-nav">
            <Nav className="w-100 d-flex flex-row align-items-center justify-content-end gap-4 pe-3">
              <Link applyBaseStyles={false} className={`no-underline ${color("/")}`} href="/">
                Home
              </Link>
              <Link applyBaseStyles={false} className={`no-underline ${color("/fuel-savings")}`} href="/fuel-savings">
                Fuel Savings
              </Link>
              <NavDropdown title="Calculate Costs" id="header-dropdown" className="text-black">
                <NavDropdown.Item
                  as={Link}
                  applyBaseStyles={false}
                  className={`no-underline ${color("/tco")}`}
                  href="/tco"
                >
                  TCO
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  applyBaseStyles={false}
                  className={`no-underline ${color("/rate-comparison")}`}
                  href="/rate-comparison"
                >
                  Rate Comparison
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  applyBaseStyles={false}
                  className={`no-underline ${color("/lcfs")}`}
                  href="/lcfs"
                >
                  LCFS
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  applyBaseStyles={false}
                  className={`no-underline ${color("/grants")}`}
                  href="/grants"
                >
                  Grants
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      <Navbar
        collapseOnSelect
        expand={false}
        className="d-flex d-lg-none bg-body-tertiary py-0 w-100 pe-2 p-print-none"
      >
        <Navbar.Brand href="/" className="py-0">
          <img src={logo} alt="The SCE Logo" className="bg-yellow p-3 p-print-none" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={"offcanvasNavbar-expand"} className="d-print-none" />
        <Navbar.Offcanvas
          id={"offcanvasNavbar-expand"}
          aria-labelledby={"offcanvasNavbarLabel-expand"}
          placement="start"
          className="d-print-none"
        >
          <Offcanvas.Header closeButton className="ps-0 pt-0 pe-5">
            <Offcanvas.Title id={"offcanvasNavbarLabel-expand"}>
              <img src={logo} alt="The SCE Logo" className="bg-yellow p-3" />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="w-100 d-flex flex-column align-items-start gap-4">
              <Link applyBaseStyles={false} className={`no-underline ${color("/")}`} href="/">
                Home
              </Link>
              <Link applyBaseStyles={false} className={`no-underline ${color("/fuel-savings")}`} href="/fuel-savings">
                Fuel Savings
              </Link>

              <NavDropdown.Item
                as={Link}
                applyBaseStyles={false}
                className={`no-underline ${color("/tco")}`}
                href="/tco"
              >
                TCO
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                applyBaseStyles={false}
                className={`no-underline ${color("/rate-comparison")}`}
                href="/rate-comparison"
              >
                Rate Comparison
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                applyBaseStyles={false}
                className={`no-underline ${color("/lcfs")}`}
                href="/lcfs"
              >
                LCFS
              </NavDropdown.Item>
              <Link applyBaseStyles={false} className={`no-underline ${color("/grants")}`} href="/grants">
                Incentives
              </Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    </>
  );
});

Header.displayName = "Header";

export default Header;
