export const toSelectOption = <T>(value: T): ISelectOption<T> => ({
  label: typeof value === "string" ? value.split("_").join(" ") : `${value}`,
  value,
});

export const toSelectOptions = <T>(values: T[]): ISelectOption<T>[] => values.map(toSelectOption);

export const removeDigits = (string: string | null | undefined) => {
  if (typeof string === "string") {
    return string.replace(/\d/g, "");
  } else {
    return string || "";
  }
};

export * from "./logging";
