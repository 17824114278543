import { findArchetype } from "@src/data/findArchetype";
import { ApiCommercialVehicle } from "@src/types/api_vehicle";
import { EVehicleDrivetrain, IArchetypeVehicle, Vehicle } from "@src/types/vehicle";
import { removeDigits } from "@src/utils";

const getFossilFuelType = (vehicle: ApiCommercialVehicle) => {
  const fuels = vehicle.fuel_types;
  if (fuels.includes("Diesel") || fuels.includes("Biodiesel")) {
    return "diesel";
  } else if (
    fuels.includes("Liquid Petroleum Gas (methane based)") ||
    fuels.includes("Compressed Natural Gas (propane based)")
  ) {
    return "lpg";
  } else if (
    fuels.includes("Midgrade") ||
    fuels.includes("Premium") ||
    fuels.includes("Regular") ||
    fuels.includes("E85")
  ) {
    return "diesel";
  } else {
    return null;
  }
};

const formatNameWord = (string: string) => {
  return string
    .split(" ")
    .map((word) => {
      if (removeDigits(word).length < 4) {
        const w = word.toUpperCase();
        if (w === "BUS") {
          return word;
        } else if (w === "VAN") {
          return word;
        } else {
          return word.toUpperCase();
        }
      } else {
        return word;
      }
    })
    .join(" ");
};

export const transformCommercialVehicle = (
  vehicle: ApiCommercialVehicle,
  archetypes: IArchetypeVehicle[],
): Vehicle | null => {
  if (!vehicle.maintenance_cost) {
    return null;
  }

  const v: Omit<Vehicle, "archetype"> = {
    id: vehicle.handle,
    handle: vehicle.handle,
    category: vehicle.form_factor === "tru" ? "TRU" : vehicle.is_offroad ? "Off-Road" : "On-Road",
    formFactor: vehicle.form_factor,
    // archetype
    name: `${formatNameWord(vehicle.make)} ${formatNameWord(vehicle.model)} ${formatNameWord(vehicle.trim)}`.trim(),
    type: vehicle.type as Vehicle["type"],
    subtype: vehicle.subtype,
    make: vehicle.make,
    model: vehicle.model,
    modelYear: vehicle.model_year,
    displayName: vehicle.display_name,
    fuelType: vehicle.fuel,
    fossilFuelType: getFossilFuelType(vehicle),
    idlingFactor: 1, // Not sure where this might come from or what it indicates. Can be 0 or 1. Comment in PGE Fleets @ src/calcs/calcKwhPerDay.js - "if this is constant, it should be added to vehicles via the computed property or on fetch or whatever"
    duty: vehicle.duty as Vehicle["duty"],
    trim: vehicle.trim,
    msrp: vehicle.msrp,
    msrpIsEstimated: vehicle.is_estimated_price,

    batteryCapacityInKwh: vehicle.battery_capacity,

    rangeInMiles: vehicle.is_offroad ? 0 : vehicle.total_range, // kWh per mile for on-road, commercial-only vehicles (US)
    rangeInHours: vehicle.is_offroad ? vehicle.total_range : 0, // kWh / hour for off-road, commercial only vehicles

    electricEfficiency: vehicle.electric_efficiency,
    electricEfficiencyUnit: vehicle.electric_efficiency_unit,

    fossilFuelEfficiency: vehicle.fossil_fuel_efficiency, // in mpg
    fossilGallonsPerHour: vehicle.fossil_fuel_efficiency,
    fossilMilesPerGallon: vehicle.fossil_fuel_efficiency,

    maintenanceCostPerHour: vehicle.is_offroad ? vehicle.maintenance_cost : 0, // miles for on-road, hours for off-road
    maintenanceCostPerMile: vehicle.is_offroad ? 0 : vehicle.maintenance_cost, // miles for on-road, hours for off-road
    fossilMaintenanceCostPerHour: vehicle.is_offroad ? vehicle.maintenance_cost : 0, // miles for on-road, hours for off-road
    fossilMaintenanceCostPerMile: vehicle.is_offroad ? 0 : vehicle.maintenance_cost, // miles for on-road, hours for off-road

    acChargingPower: vehicle.ac_charging_power,
    dcChargingPower: vehicle.dc_charging_power,
    doors: vehicle.doors,
    seatsMin: vehicle.seats_min,
    seatsMax: vehicle.seats_max,
    cargoVolumeMin: vehicle.cargo_volume_min,
    cargoVolumeMax: vehicle.cargo_volume_max,
    acceleration: vehicle.acceleration,
    officialWebsiteUrl: vehicle.official_website_url,
    electricRange: vehicle.electric_range,
    totalRange: vehicle.total_range,
    drivetrain: vehicle.drivetrain as EVehicleDrivetrain,
    curbWeight: vehicle.curb_weight,
    imageUrl: vehicle.images.find(({ url_thumbnail }) => url_thumbnail)?.url_full,
    images: vehicle.images.map((image) => ({
      altText: image.alt,
      urlFull: image.url_full,
      urlThumbnail: image.url_thumbnail,
    })),
    gvwr: vehicle.gvwr,
    horsepower: vehicle.horsepower,
    plugTypes: vehicle.plug_types,
    customPrice: vehicle.custom_price,
    isCustom: false,
  };

  return {
    ...v,
    archetype: {
      ...(findArchetype(archetypes, v as Vehicle) as IArchetypeVehicle),
      duty: v.duty,
      msrpEstimated: v.msrp,
    },
  };
};
