import { ERateName } from "@src/types";

const TOU_EV_7_MAX_KW = 20;
const TOU_EV_8_MAX_KW = 500;

const calcIdealRateName = (loadProfileValues: number[]): ERateName => {
  const max = Math.max(...loadProfileValues);

  if (max <= TOU_EV_7_MAX_KW) {
    return ERateName.TOU_EV_7;
  } else if (max <= TOU_EV_8_MAX_KW) {
    return ERateName.TOU_EV_8;
  } else {
    return ERateName.TOU_EV_9;
  }
};

export default calcIdealRateName;
