import { EApiPath } from "@src/api/fetchRecords";
import { useApi } from "@src/api/useApi";
import groupByChargers from "@src/calcs/groupByChargers";
import { useInputs } from "@src/hooks/useInputs";
import { ApiProjectIncentive, TCombinedIncentive } from "@src/types/incentives";
import { IApplicationInputs } from "@src/types/inputs";
import { logDev } from "@src/utils";
import { useCallback, useMemo } from "react";
import useCommercialIncentives from "./useCommercialIncentives";

const yesNoToTrueFalse = (yesNo: "yes" | "no" | "Yes" | "No") => yesNo.toLowerCase() === "yes";

export default function useProjectIncentives(overrideInputs?: IApplicationInputs, skipinitialFetch?: boolean) {
  const inputs = {
    ...useInputs(),
    ...overrideInputs,
  };
  const {
    vehicleSets,
    isTaxExempt,
    isDAC,
    organizationType,
    yearsOfOperation,
    zipCode,
    btmConstructionCost,
    btmEvseCost,
    isPublic,
    replacesIceVehicle,
  } = inputs;

  const { isLoading: isLoadingIncentives, items: commercialIncentives } = useCommercialIncentives(skipinitialFetch);

  const chargerSets = useMemo(
    () => (skipinitialFetch || isLoadingIncentives ? [] : groupByChargers(vehicleSets)),
    [isLoadingIncentives, skipinitialFetch, vehicleSets],
  );

  const isTransitParkingFacility =
    skipinitialFetch || isLoadingIncentives || vehicleSets.some(({ vehicle }) => vehicle?.formFactor === "transit_bus");

  const payload = useMemo(() => {
    logDev("useProjectIncentives.payload - memoize");
    return isLoadingIncentives
      ? {}
      : {
          project: {
            project_type: "fleets",
            locationEnum: isTransitParkingFacility ? "transit_parking_facility" : "workplace_facility",
            estimated_installation_costs: btmConstructionCost,
            projected_cost: btmConstructionCost + btmEvseCost,
            years_of_operation: yearsOfOperation,

            organization: {
              organization_type: organizationType,
              is_tax_exempt: yesNoToTrueFalse(isTaxExempt as "Yes" | "No"),
              is_public: yesNoToTrueFalse(isPublic as "Yes" | "No"),
            },

            site: {
              address: {
                postcode: zipCode,
                region: "CA",
                power_supplier: {
                  name: "SCE",
                },
              },
              in_a_dac: yesNoToTrueFalse(isDAC as "Yes" | "No"),
            },

            chargers: chargerSets.map((chargerSet) => ({
              count: chargerSet.minimumRequired,
              identifier: chargerSet.charger?.type,
              is_open_to_public: false,
              is_networked: true,
              details: {
                type: "charger",
                is_energy_star_certified: true,
                purchase_price: chargerSet.charger?.estimatedCost,
                charger_power_level: chargerSet.charger?.portKw,
                number_of_connections: chargerSet.charger?.ports,
                charger_type: chargerSet.charger?.chargerType,
              },
            })),

            vehicles: vehicleSets.map(({ vehicle, vehicleCount }) => ({
              count: vehicleCount,
              type: "vehicle",
              details: {
                type: "vehicle",
                subtype: vehicle?.subtype,
                make: vehicle?.make || "Custom",
                model: vehicle?.model,
                trim: vehicle?.trim,
                fuel: vehicle?.fuelType || "bev",
                weight_class: vehicle?.archetype?.weightClass,
                model_year: vehicle?.modelYear,
                vehicle_type: vehicle?.type,
                form_factor: vehicle?.formFactor,
                msrp: vehicle?.msrp,
                purchase_price: vehicle?.msrp,
                lift_capacity: vehicle?.archetype?.liftCapacity,
                battery_capacity: vehicle?.batteryCapacityInKwh,
                years_of_operation: yearsOfOperation,
                replaces_ice_vehicle: yesNoToTrueFalse(replacesIceVehicle as "Yes" | "No"),
                identifier: vehicle?.id,
                lease_length: null,
              },
            })),
          },
        };
  }, [
    btmConstructionCost,
    btmEvseCost,
    chargerSets,
    isDAC,
    isLoadingIncentives,
    isPublic,
    isTaxExempt,
    isTransitParkingFacility,
    organizationType,
    replacesIceVehicle,
    vehicleSets,
    yearsOfOperation,
    zipCode,
  ]);

  const transform = useCallback((item: ApiProjectIncentive) => item, []);
  const options = useMemo(
    () => ({
      method: "POST" as const,
      params: payload,
      skipinitialFetch: skipinitialFetch,
      skipRefresh: false,
      resultsKey: "evaluations.incentives",
    }),
    [payload, skipinitialFetch],
  );

  const {
    items: projectIncentives,
    get: getProjectIncentives,
    isLoading,
  } = useApi<ApiProjectIncentive, ApiProjectIncentive>(EApiPath.PROJECT, transform, options);

  const combinedIncentives = [] as TCombinedIncentive[];

  if (!isLoading) {
    combinedIncentives.push(
      ...(projectIncentives
        .map((projectIncentive) => {
          const matchingCommercialIncentive = commercialIncentives.find(
            (commercialIncentive) => commercialIncentive.incentive_handle === projectIncentive.handle,
          );
          if (!matchingCommercialIncentive) return null;

          return {
            ...projectIncentive,
            ...matchingCommercialIncentive,
          };
        })
        .filter(Boolean) as TCombinedIncentive[]),
    );
  }

  return { isLoading, get: getProjectIncentives, combinedIncentives };
}
