import { useArchetypeVehicles } from "@src/api/useArchetypeVehicles";
import { useCommercialChargers } from "@src/api/useCommercialChargers";
import { useCommercialVehicles } from "@src/api/useCommercialVehicles";
import useProjectIncentives from "@src/api/useProjectIncentives";
import calcIdealRateName from "@src/calcs/calcIdealRateName";
import calcTotalChargerSetLoadProfile from "@src/calcs/calcTotalChargerSetLoadProfile";
import calcTotalWeeklyLoadProfile from "@src/calcs/calcTotalWeeklyLoadProfile";
import defaults from "@src/context/defaults";
import { useComputeChargerSets } from "@src/hooks/useComputeChargerSets";
import { useComputeRateInfo } from "@src/hooks/useComputeRateInfo";
import { useComputeVehicleSets } from "@src/hooks/useComputeVehicleSets";
import { IApplicationInputs } from "@src/types/inputs";
import { Vehicle } from "@src/types/vehicle";
import { PropsWithChildren, useCallback, useMemo, useReducer } from "react";
import InputContext from "../../context";

const CACHE_KEY = "@sce/fleets/inputs";

const parse = (item: string | null) => {
  return item ? JSON.parse(item) : {};
};

const DEFAULT_VALUES = { ...defaults, ...parse(localStorage.getItem(CACHE_KEY)) };

const reducer = (current: IApplicationInputs, changed: Partial<IApplicationInputs>) => {
  const newVehicles = Array.isArray(changed.vehicles)
    ? [
        ...current.vehicles,
        ...changed.vehicles,
        ...(changed.customVehicles || []).map((vehicle) => ({
          ...vehicle,
          fossilMilesPerGallon: vehicle.fossilFuelEfficiency,
          category: "On-Road",
        })),
      ]
    : current.vehicles;

  const newChargers = Array.isArray(changed.chargers)
    ? [...current.chargers, ...changed.chargers, ...(changed.customChargers || [])]
    : current.chargers;

  const newChargingFacilityType = changed.chargingFacilityType
    ? changed.chargingFacilityType
    : current.chargingFacilityType;

  const newInputs = {
    ...current,
    ...changed,
    chargingFacilityType: newChargingFacilityType,
    vehicles: newVehicles as Vehicle[],
    chargers: newChargers,
  };

  const {
    chargers: _chargers,
    vehicles: _vehicles,
    archetypes: _archetypes,
    incentives: _incentives,
    loading: _loading,
    isPrinting: _isPrinting,
    ...rest
  } = newInputs;
  localStorage.setItem(CACHE_KEY, JSON.stringify(rest));
  return newInputs;
};

const Inputs = ({ children }: PropsWithChildren) => {
  const [inputs, dispatch] = useReducer(reducer, DEFAULT_VALUES);

  const { items: chargers, get: _getChargers, isLoading: isLoadingChargers } = useCommercialChargers(false);
  const { items: archetypes, get: _getArchetypes, isLoading: isLoadingArchetypes } = useArchetypeVehicles(false);
  const { items: vehicles, get: _getVehicles, isLoading: isLoadingVehicles } = useCommercialVehicles(false);

  const { combinedIncentives: incentives, isLoading: isLoadingProjectIncentives } = useProjectIncentives(inputs, false);

  const computeChargerSets = useComputeChargerSets();
  const computeVehicleSets = useComputeVehicleSets();
  const computeRateInfo = useComputeRateInfo();

  // Commercial incentives are loaded by project incentives
  // const { incentives, get, isLoading: isLoadingCommercialIncentives } = useCommercialIncentives(false);

  const loading = useMemo(
    () => [isLoadingChargers, isLoadingVehicles, isLoadingArchetypes, isLoadingProjectIncentives].some(Boolean),
    [isLoadingArchetypes, isLoadingChargers, isLoadingProjectIncentives, isLoadingVehicles],
  );

  const setInput = useCallback((changed: Partial<IApplicationInputs>) => {
    dispatch(changed);
  }, []);

  const value = useMemo(() => {
    const computedChargerSets = computeChargerSets(chargers, inputs.chargerSets);
    const computedVehicleSets = computeVehicleSets(
      vehicles,
      chargers,
      inputs.customVehicles,
      inputs.customChargers,
      inputs.vehicleSets,
    );

    const weeklyLoadProfileValues =
      inputs.estimationApproach === "Vehicles"
        ? calcTotalWeeklyLoadProfile(inputs.chargers, inputs.vehicleSets)
        : calcTotalChargerSetLoadProfile({
            chargerSets: inputs.chargerSets,
            timeRange: "weekly",
            facilityType: inputs.chargingFacilityType,
          });

    const idealRateName = calcIdealRateName(weeklyLoadProfileValues);

    return {
      ...inputs,
      idealRateName,
      archetypes,
      chargers,
      incentives,
      vehicles,
      chargerSets: computedChargerSets,
      vehicleSets: computedVehicleSets,
      ...computeRateInfo.chargerSets({
        chargerSets: computedChargerSets,
        chargingFacilityType: inputs.chargingFacilityType,
      }),
      ...computeRateInfo.vehicleSets({ vehicleSets: computedVehicleSets, chargers }),
      loading,
      setInput,
      setInputs: dispatch,
    };
  }, [
    computeChargerSets,
    chargers,
    inputs,
    computeVehicleSets,
    vehicles,
    archetypes,
    incentives,
    computeRateInfo,
    loading,
    setInput,
  ]);

  return <InputContext.Provider value={value as IApplicationInputs}>{children}</InputContext.Provider>;
};

export default Inputs;
