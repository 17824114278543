import { ApiArchetypeVehicle } from "@src/types/api_vehicle";
import { IArchetypeVehicle } from "@src/types/vehicle";

export const transformArchetypeVehicle = (archetype: ApiArchetypeVehicle): IArchetypeVehicle => {
  return {
    id: archetype.handle,
    handle: archetype.handle, // Internal identifier for this archetype
    name: archetype.description, // description
    // description: string; // Archetype description
    type: archetype.type,
    subtype: archetype.subtype,
    imageUrl: archetype.image_url,
    formFactor: archetype.form_factor, // form_factor
    gvwr: archetype.gvwr,
    msrp: archetype.msrp,
    msrpEstimated: archetype.msrp,
    duty: archetype.handle.includes("heavy")
      ? "heavy"
      : archetype.handle.includes("medium")
        ? "medium"
        : archetype.handle.includes("light")
          ? "light"
          : "n/a",
    evFuelType: archetype.ev_fuel_type,
    category: archetype.form_factor === "tru" ? "TRU" : archetype.on_off_road, // on_off_road, Possible values: "Off-Road" "On-Road
    // ev_msrp: number;
    batteryCapacityInKwh: archetype.ev_battery_capacity, // ev_battery_capacity
    // ev_electric_efficiency: number;
    // ev_ac_charging_power: number;
    // ev_dc_charging_power: number;
    rangeInMiles: archetype.ev_range + archetype.ice_range, // ev_range
    rangeInHours: archetype.ev_range + archetype.ice_range, // bellawatt
    // ice_range_sources: number;

    weightClass: archetype.weight_class,
    liftCapacity: archetype.lift_capacity,

    maintenanceCostPerMile: archetype.ev_maint_cost, // ev_maint_cost
    maintenanceCostPerHour: archetype.ev_maint_cost, // ev_maint_cost

    fossilFuelType: archetype.ice_fuel_type, // ice_fuel_type
    fossilFuelMsrp: archetype.ice_msrp, // ice_msrp
    fossilMaintenanceCostPerMile: archetype.ice_maint_cost, // ice_maint_cost
    fossilMaintenanceCostPerHour: archetype.ice_maint_cost, // ice_maint_cost
    fossilGallonsPerHour: archetype.ice_fossil_fuel_efficiency, // ice_fossil_fuel_efficiency
    fossilMilesPerGallon: archetype.ice_fossil_fuel_efficiency, // bellawatt
  };
};
