import { VehicleSetCacheContext } from "@src/context/vehicle_sets/VehicleSetCacheContext";
import { IVehicleSet } from "@src/types/sets";
import { PropsWithChildren, useMemo, useState } from "react";

const VehicleSetContext = ({ children }: PropsWithChildren) => {
  const [selectedVehicleSet, setSelectedVehicleSet] = useState<IVehicleSet | null>(null);

  const value = useMemo(
    () => ({
      selectedVehicleSet,
      setSelectedVehicleSet,
    }),
    [selectedVehicleSet],
  );

  return <VehicleSetCacheContext.Provider value={value}>{children}</VehicleSetCacheContext.Provider>;
};

export default VehicleSetContext;
