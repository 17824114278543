function hourBuilder(start: number, end: number): number[] {
  const hours = new Array(24).fill(0);
  let i = start;
  if (start < end) {
    for (i; i < end; i++) {
      hours[i] = 1;
    }
    return hours;
  }

  for (i; i < 24; i++) {
    hours[i] = 1;
  }
  i = 0;

  for (i; i <= end; i++) {
    hours[i] = 1;
  }
  return hours;
}

const TIME_PERIODS = [
  {
    key: "12am_6am",
    label: "Midnight through 6am",
    hours: hourBuilder(0, 6),
  },
  {
    key: "6am_12pm",
    label: "6am through Noon",
    hours: hourBuilder(6, 12),
  },
  {
    key: "12pm_6pm",
    label: "Noon through 6pm",
    hours: hourBuilder(12, 18),
  },
  {
    key: "6pm_12am",
    label: "6pm through Midnight",
    hours: hourBuilder(18, 0),
  },
];

export default TIME_PERIODS;
