import createCache from "@src/api/cache";
import { logDev } from "@src/utils";
import {
  getIndexDBValue,
  getPastMonthObjectStoreKeys,
  getTodayObjectStoreKey,
  removeIndexDBValue,
  setIndexDBValue,
} from "@src/utils/indexDBCaching";
import { isEmpty } from "lodash";

const TOKEN = import.meta.env.VITE_API_TOKEN || "805f1854eb6a4060b6b63921923100ac";
// "bf00f486a40c475285131bb00a2988c0"

const BASE_URL = {
  POST: "https://commercial.production.zappyride.com",
  GET: "https://api.production.zappyride.com",
};

const cache = createCache<ReturnType<Response["json"]>>({ ttl: 100 /* ms */ });

export enum EApiPath {
  COMMERCIAL_CHARGERS = "commercial/chargers",
  COMMERCIAL_VEHICLES = "commercial/vehicles",
  ARCHETYPE = "archetype-vehicles",
  PROJECT = "project",
  COMMERCIAL_INCENTIVES = "commercial/incentives",
}

export const apiSettings = {
  url: BASE_URL,
  token:
    (import.meta.env.VITE_ENVIRONMENT === "production"
      ? import.meta.env.VITE_API_TOKEN_PRODUCTION
      : import.meta.env.VITE_ENVIRONMENT === "staging"
        ? import.meta.env.VITE_API_TOKEN_STAGING
        : import.meta.env.VITE_API_TOKEN_DEVELOPMENT) || TOKEN,
};

// export const post = (url: string, data: Record<string, any>) => axios.post(`${BASE_URL}${url}`, data);

interface IOptions {
  params?: Record<string, string | null>;
  method?: "GET" | "POST";
  skipRefresh?: boolean;
}

export const fetchRecords = async (path: EApiPath, { params = {}, method = "GET", skipRefresh = false }: IOptions) => {
  const url = new URL(`${BASE_URL[method]}/${path}`);

  // auto-remove null params, for convenience
  const qs = (() => {
    if (method === "POST") {
      return JSON.stringify(params);
    } else {
      const stringParams = Object.fromEntries(
        Object.entries(params).filter((pair): pair is [string, string] => pair[1] !== null),
      );
      const searchParams = new URLSearchParams(stringParams);
      return searchParams.toString();
    }
  })();

  const cacheKeyPrefix = qs
    ? `${path.replaceAll("/", "-")}-${qs.replace("?", "-").replaceAll("&", "-")}`
    : path.replaceAll("/", "-");

  const indexDBStorageKey = getTodayObjectStoreKey(cacheKeyPrefix);
  // Keep the users indexdb relatively clean by deleting keys for past two weeks, if they exist
  getPastMonthObjectStoreKeys(cacheKeyPrefix).forEach(async (objectStoreKey) => {
    await removeIndexDBValue(objectStoreKey);
  });

  const refreshData = async () => {
    if (skipRefresh) {
      logDev("fetchRecords.refreshData - SKIP refreshing for:", path);
      return;
    }

    const controller = new AbortController();

    logDev("fetchRecords.refreshData - refreshing for:", path);
    if (method === "GET") {
      url.search = qs;
    }

    const json = await cache.getOrPut(url.toString(), () =>
      window
        .fetch(url.toString(), {
          method: method,
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${TOKEN}`,
          },
          body: method === "POST" ? qs : undefined,
          signal: controller.signal,
        })
        .then((response) => response.json())
        .then((j) => {
          if (j.code >= 400) {
            throw new Error(j.message);
          }
          setIndexDBValue(indexDBStorageKey, j);
          return j;
        })
        .catch((e) => {
          console.error(e);
          return {};
        }),
    );

    return json;
  };

  const stored = await getIndexDBValue(indexDBStorageKey);
  if (!isEmpty(stored)) {
    let errored = false;
    try {
      logDev(`fetchRecords - return stored data for ${path}`);
      return stored;
    } catch (e) {
      console.error(e);
      errored = true;
      return await refreshData();
    } finally {
      if (!errored) {
        refreshData();
      }
    }
  } else {
    return await refreshData();
  }
};
