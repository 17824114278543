import TrackableLink from "./TrackableLink";

const TalkToAnSCESpecialist = () => (
  <TrackableLink
    event="Talk to an SCE Specialist"
    category="Clicked to speak to EV Specialist"
    href={`mailto:TCO@sce.com?subject=SCE Fleets inquiry via ${window.location.hostname}`}
    className="btn btn-success bold text-white rounded-0"
  >
    Talk to an SCE Specialist
  </TrackableLink>
);

export default TalkToAnSCESpecialist;
