import { ICharger } from "@src/types/charging";
import { IVehicleSet } from "@src/types/sets";
import { Vehicle } from "@src/types/vehicle";
import { useCallback } from "react";

export class VehicleIdentificationError extends Error {}

// Assign vehicles to vehicle sets
// Assign archetypes to vehicle sets
// Assign chargers to vehicleSet.chargingWindows
export const useComputeVehicleSets = () => {
  // const { vehicles: _vehicles, chargers: _chargers, customChargers, customVehicles } = useInputs();

  return useCallback(
    (
      _vehicles: Vehicle[],
      _chargers: ICharger[],
      customVehicles: Vehicle[],
      customChargers: ICharger[],
      newVehicleSets: IVehicleSet[],
    ) => {
      const vehicles = [..._vehicles, ...customVehicles];
      const chargers = [..._chargers, ...customChargers];

      return newVehicleSets
        .map((vehicleSet) => {
          const vehicle = vehicles.find(({ id }) => id === vehicleSet.vehicle_id);

          if (!vehicle) {
            // throw new VehicleIdentificationError(JSON.stringify(vehicleSet));
            return null;
          }

          if (!vehicle.archetype) {
            console.error(
              `Vehicle archetype with category [${vehicle.category}], type [${vehicle.type}], and subtype [${vehicle.subtype}] does not exist.`,
            );
            // throw new VehicleIdentificationError(JSON.stringify(vehicleSet));
            return null;
          }

          const chargingWindows = vehicleSet.chargingWindows.map((window) => ({
            ...window,
            charger: chargers.find(({ type }) => type === window.type),
          }));

          return {
            ...vehicleSet,
            vehicle: {
              ...vehicle,
              fossilFuelEfficiency: vehicle.archetype.fossilMilesPerGallon || vehicle.fossilFuelEfficiency,
              fossilMilesPerGallon: vehicle.archetype.fossilMilesPerGallon || vehicle.fossilMilesPerGallon,
              fossilGallonsPerHour: vehicle.archetype.fossilGallonsPerHour || vehicle.fossilGallonsPerHour,
              fossilFuelType: vehicle.archetype.fossilFuelType || vehicle.fossilFuelType,
            },
            archetype: vehicle.archetype,
            chargingWindows,
          };
        })
        .filter(Boolean) as IVehicleSet[];
    },
    [],
  );
};
