import ASSUMPTIONS from "@src/data/ASSUMPTIONS";
import { ICharger } from "@src/types/charging";
import { IVehicleSet } from "@src/types/sets";
import { max, min, sum, times } from "lodash";
import calcPlugSchedule from "../calcPlugSchedule";

const calcBatterySchedule = (chargers: ICharger[], vehicleSet: IVehicleSet, applyMaxCharging?: boolean) => {
  const { vehicle, vehicleCount } = vehicleSet;

  if (!vehicle || !vehicleCount) {
    console.error(
      "calcBatteryScheule.ts - No vehicle in passed vehicle set. Return 0's and an empty weeklyBatterySchedule.",
    );
    return {
      weeklyBatterySchedule: [],
      totals: {
        chargeAtHourStart: 0,
        chargeAtHourEnd: 0,
        kwhPowerDrawn: 0,
        kwhConsumed: 0,
      },
      minStateOfCharge: 0,
    };
  }

  const plugSchedule = calcPlugSchedule(chargers, vehicleSet);

  const kwhPerMile = vehicle.batteryCapacityInKwh / vehicle.rangeInMiles;
  const kwhPerHour = vehicle.batteryCapacityInKwh / vehicle.electricRange;
  const idling = vehicle.category?.toUpperCase() === "TRU" ? 1 - ASSUMPTIONS.TRU_IDLING_FACTOR : 1;
  const kwhPerDay =
    vehicle.category === "On-Road" ? vehicleSet.milesPerWorkday * kwhPerMile : vehicleSet.hoursPerWorkday * kwhPerHour;

  const hoursOfCharging = plugSchedule.filter((plug) => plug.portKw).length;
  const hoursAwayFromChargers = 24.0 - hoursOfCharging;
  const consumedKwhPerHour = (kwhPerDay * idling) / hoursAwayFromChargers;
  const evenPowerDrawInKwhPerHour = kwhPerDay / hoursOfCharging;

  const vehicleBatterySchedule = times(7 * 24, () => ({
    chargeAtHourStart: 0,
    chargeAtHourEnd: 0,
    kwhPowerDrawn: 0,
    kwhConsumed: 0,
  }));

  for (let idx = 0; idx < vehicleBatterySchedule.length; idx++) {
    const hour = idx % 24;
    const day = Math.floor(idx / 24.0) === 6 ? 0 : Math.floor(idx / 24.0) + 1.0;

    const isOperating = vehicleSet.workdays.includes(day);
    const { chargingApproach } = plugSchedule[hour];
    const capacity = vehicle.batteryCapacityInKwh;

    const chargeAtHourStart = (idx === 0 ? capacity : vehicleBatterySchedule[idx - 1].chargeAtHourEnd) as number; // ?? 0

    const portKw = plugSchedule[hour].portKw;
    const availablePowerInKw =
      applyMaxCharging || chargingApproach === "Max"
        ? portKw
        : typeof portKw === "number"
          ? Math.min(portKw, evenPowerDrawInKwhPerHour)
          : evenPowerDrawInKwhPerHour; // even if approach is "even", the available power shouldn't surpass the charger's kW availability

    const actualPowerDrawInKwh = (() => {
      if (!chargingApproach) return 0;

      if (capacity) {
        if (availablePowerInKw) {
          return Math.min(availablePowerInKw, capacity - chargeAtHourStart);
        } else {
          return capacity - chargeAtHourStart;
        }
      }
      return 0;
    })();

    const kwhConsumed = isOperating && !chargingApproach ? consumedKwhPerHour : 0;

    const chargeAtHourEnd = capacity
      ? Math.min(capacity, chargeAtHourStart + actualPowerDrawInKwh - kwhConsumed)
      : chargeAtHourStart + actualPowerDrawInKwh - kwhConsumed;

    vehicleBatterySchedule[idx].chargeAtHourStart = chargeAtHourStart;
    vehicleBatterySchedule[idx].chargeAtHourEnd = chargeAtHourEnd;
    vehicleBatterySchedule[idx].kwhPowerDrawn = actualPowerDrawInKwh;
    vehicleBatterySchedule[idx].kwhConsumed = kwhConsumed;
  }

  const batterySchedule = vehicleBatterySchedule.map((schedule) => ({
    chargeAtHourStart: schedule.chargeAtHourStart * vehicleSet.vehicleCount,
    chargeAtHourEnd: schedule.chargeAtHourEnd * vehicleSet.vehicleCount,
    kwhPowerDrawn: schedule.kwhPowerDrawn * vehicleSet.vehicleCount,
    kwhConsumed: schedule.kwhConsumed * vehicleSet.vehicleCount,
  }));

  return {
    weeklyBatterySchedule: batterySchedule,
    totals: {
      chargeAtHourStart: sum(batterySchedule.map((schedule) => schedule.chargeAtHourStart)),
      chargeAtHourEnd: sum(batterySchedule.map((schedule) => schedule.chargeAtHourEnd)),
      kwhPowerDrawn: sum(batterySchedule.map((schedule) => schedule.kwhPowerDrawn)),
      kwhConsumed: sum(batterySchedule.map((schedule) => schedule.kwhConsumed)),
    },
    minStateOfCharge:
      (min(batterySchedule.map((schedule) => schedule.chargeAtHourStart)) ?? 0) /
      (max(batterySchedule.map((schedule) => schedule.chargeAtHourStart)) ?? 1),
    chargeAtStartOfWeek: batterySchedule[0].chargeAtHourStart,
    chargeAtEndOfWeek: batterySchedule[batterySchedule.length - 1].chargeAtHourEnd,
    hoursInWeek: batterySchedule.length, // if 168, includes weekends
  };
};

export default calcBatterySchedule;
