export const logDev = (...args: any[]) => {
  if (window.location.hostname === "localhost") {
    const [message, ...extra] = args;

    if (message instanceof DOMException) {
      console.error(message);
    } else if (typeof message === "string") {
      console.log(`(dev) ${message}`, ...extra);
    } else {
      console.log("(dev)", message, ...extra);
    }
  }
};
