import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorker from "./serviceWorker";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import weekOfYear from "dayjs/plugin/weekOfYear"; // ES 2015
import isLeapYear from "dayjs/plugin/isLeapYear";
dayjs.extend(isLeapYear);
dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/New_York");

import App from "./App";

import "@src/App.scss";
import "@src/print.css";

// const config = Honeybadger.configure({
//   apiKey: "b38380e1",
//   environment: "production",
// });

// const honeybadger = Honeybadger.configure(config);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    {/* <HoneybadgerErrorBoundary honeybadger={honeybadger} ErrorComponent={ErrorPage}> */}
    <App />
    {/* </HoneybadgerErrorBoundary> */}
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
