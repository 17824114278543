import { EApiPath } from "@src/api/fetchRecords";
import { transformArchetypeVehicle } from "@src/api/transform/transformArchetypeVehicle";
import { useApi } from "@src/api/useApi";
import { useInputs } from "@src/hooks/useInputs";
import { useMemo } from "react";

export const useArchetypeVehicles = (skipinitialFetch?: boolean) => {
  const { zipCode } = useInputs();

  const options = useMemo(
    () => ({
      skipinitialFetch,
      skipRefresh: skipinitialFetch,
      resultsKey: "archetypes",
      params: {
        postcode: zipCode,
      },
    }),
    [skipinitialFetch, zipCode],
  );
  return useApi(EApiPath.ARCHETYPE, transformArchetypeVehicle, options);
};
