// import ErrorBoundary from "@src/components/ErrorBoundary";
import useScrollToTop from "@src/hooks/useScrollToTop";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Inputs from "@src/components/inputs/Inputs";
import CenteredLoadingSpinner from "@src/components/loading/CenteredLoadingSpinner";
import { useInputs } from "@src/hooks/useInputs";
import { useUuid } from "@src/hooks/useUuid";
import NotFoundPage from "@src/pages/NotFoundPage";
import GaTracker from "@src/utils/GaTracker";
import { isEmpty } from "lodash";
import { lazy, Suspense, useEffect } from "react";
import VehicleSetContext from "@src/context/vehicle_sets/VehicleSetContext";

const ErrorPage = lazy(() => import("@src/pages/ErrorPage"));
const FuelSavingsCalculator = lazy(() => import("@src/pages/FuelSavings/FuelSavingsPage"));
const GrantsAndIncentivesPage = lazy(() => import("@src/pages/GrantsAndIncentivesPage"));
const Home = lazy(() => import("@src/pages/Home"));
const LcfsPage = lazy(() => import("@src/pages/LcfsPage"));
const RateComparisonPage = lazy(() => import("@src/pages/RateComparisonPage"));
const TotalCostOfOwnership = lazy(() => import("@src/pages/TotalCostOfOwnership/TotalCostOfOwnershipPage"));
const VehicleCatalog = lazy(() => import("@src/pages/VehicleCatalogPage"));

const RoutedApplication = () => {
  useScrollToTop();

  const { uuid } = useUuid();
  useEffect(() => {
    GaTracker.initialize({
      gaOptions: {
        userId: uuid,
      },
    });
  }, [uuid]);

  return (
    <Inputs>
      <Routes>
        <Route
          path="/tco"
          element={
            <Suspense fallback={<CenteredLoadingSpinner />}>
              <VehicleSetContext>
                <Loading Component={TotalCostOfOwnership} />
              </VehicleSetContext>
            </Suspense>
          }
        />
        <Route
          path="/fuel-savings"
          element={
            <Suspense fallback={<CenteredLoadingSpinner />}>
              <VehicleSetContext>
                <Loading Component={FuelSavingsCalculator} />
              </VehicleSetContext>
            </Suspense>
          }
        />
        <Route
          path="/rate-comparison"
          element={
            <Suspense fallback={<CenteredLoadingSpinner />}>
              <VehicleSetContext>
                <Loading Component={RateComparisonPage} />
              </VehicleSetContext>
            </Suspense>
          }
        />
        <Route
          path="/lcfs"
          element={
            <Suspense fallback={<CenteredLoadingSpinner />}>
              <VehicleSetContext>
                <Loading Component={LcfsPage} />
              </VehicleSetContext>
            </Suspense>
          }
        />
        <Route
          path="/grants"
          element={
            <Suspense fallback={<CenteredLoadingSpinner />}>
              <VehicleSetContext>
                <Loading Component={GrantsAndIncentivesPage} />
              </VehicleSetContext>
            </Suspense>
          }
        />
        <Route
          path="/error"
          element={
            <Suspense fallback={<CenteredLoadingSpinner />}>
              <Loading Component={ErrorPage} />
            </Suspense>
          }
        />
        <Route
          path="/vehicle-catalog"
          element={
            <Suspense fallback={<CenteredLoadingSpinner />}>
              <Loading Component={VehicleCatalog} />
            </Suspense>
          }
        />
        <Route path="/" element={<Loading Component={Home} />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Inputs>
  );
};

const Loading = ({ Component }: any) => {
  const { vehicles, chargers } = useInputs();

  if (isEmpty(vehicles) || isEmpty(chargers)) {
    return <CenteredLoadingSpinner />;
  } else {
    return <Component />;
  }
};

const App = () => {
  return (
    <Router>
      {/* <ErrorBoundary> */}
      <RoutedApplication />
      {/* </ErrorBoundary> */}
    </Router>
  );
};

export default App;
