import { EApiPath, fetchRecords } from "@src/api/fetchRecords";
import { logDev } from "@src/utils";
import { get, isPlainObject, uniqBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

interface IReturnValue<T> {
  get: () => Promise<T[]>;
  isLoading: boolean;
  items: T[];
}

interface IOptions {
  resultsKey?: string;
  params?: Record<string, any>;
  method?: "GET" | "POST";
  skipinitialFetch?: boolean;
  skipRefresh?: boolean;
  uniqByKey?: string;
}

export const useApi = <T, U>(
  apiEndpoint: EApiPath,
  transformer: (apiItem: U) => T | null,
  options: IOptions,
): IReturnValue<T> => {
  const [items, setItems] = useState<T[]>([]);

  const transform = useMemo(() => transformer, [transformer]);
  const opts = useMemo(() => options, [options]);
  const { params = {}, method = "GET", skipinitialFetch = false, skipRefresh = false, resultsKey, uniqByKey } = opts;

  const request = useCallback((): Promise<T[]> => {
    logDev("useApi.request - ", apiEndpoint);

    return fetchRecords(apiEndpoint, {
      params,
      method: method,
      skipRefresh,
    })
      .then((response) => {
        if (!isPlainObject(response)) {
          return [];
        }

        const key = resultsKey ?? Object.keys(response).filter((k) => !["message", "code"].includes(k))[0];
        const data = get(response, key);
        if (Array.isArray(data)) {
          const result = data.reduce((sum, item) => {
            const t = transform(item);
            return t ? sum.concat(t) : sum;
          }, []) as T[];
          if (uniqByKey) {
            return uniqBy(result, uniqByKey);
          } else {
            return result;
          }
        } else {
          return [];
        }
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  }, [apiEndpoint, params, method, skipRefresh, resultsKey, transform, uniqByKey]);

  const fn = useMemo(
    () => () => {
      if (!skipinitialFetch) {
        request().then(setItems).catch(console.error);
      }
    },
    [request, skipinitialFetch],
  );

  useEffect(fn, [fn]);

  return useMemo(
    () => ({
      items,
      get: request,
    }),
    [items, request],
  ) as IReturnValue<T>;
};
