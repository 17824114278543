import ASSUMPTIONS from "@src/data/ASSUMPTIONS";
import { ERateName } from "@src/types";
import { ICharger, IChargingWindow, TChargingFacilityType } from "@src/types/charging";
import { TCombinedIncentive } from "@src/types/incentives";
import { IApplicationInputs } from "@src/types/inputs";
import { IChargerSet, IChargerSetTimePeriod, IVehicleSet } from "@src/types/sets";
import { TRateYear } from "@src/types/time/days_of_week";
import { IArchetypeVehicle, Vehicle } from "@src/types/vehicle";
import TIME_PERIODS from "@src/utils/timePeriods";
import { times } from "lodash";

export const DEFAULT_ADDITIONAL_CHARGING_WINDOW: IChargingWindow = {
  start: 12,
  finish: 15,
  chargingApproach: "Max",
  type: "DCFC - 50kW",
  portKw: 50,
  charger: {
    id: "ABB_Terra_54_HV",
    type: "DCFC - 50kW",
    ports: 1,
    portKw: 50,
    estimatedCost: 58538,
    chargerType: "dc_fast_charging",
    imageUrl: "https://assets.zappyride.com/img/chargers/ABB%20Terra%2054%20HV.png",
    make: "ABB",
    model: "Terra 54",
    model_variant: "HV Single Port",
    price: 58538,
    price_src: "",
    is_estimated_price: true,
    form_factor: "Pedestal",
    currents: ["DC"],
    amps: 0,
    amps_dc: 125,
    volts_ac: 0,
    volts_dc: 920,
    kw_ac: 0,
    kw_dc: 50,
    cord_length: 26,
    outlets: ["Hardwired"],
    connector_types: ["CCS1"],
    num_of_ports: 1,
    load_balancing_protocol: ["OCPP"],
    ocpp_version: "1.6",
    oscp_version: "",
    vgi_capable: false,
    enterprise_mgmt_capable: false,
    wifi: false,
    cellular_support: ["2G", "3G", "4G", "LTE"],
    ethernet: true,
    bluetooth: false,
    credit_card_reader: false,
    nfc_reader: true,
    rfid_reader: true,
    warranty: 2,
    safe_outdoors: true,
    made_in_america: false,
    details_link: "https://new.abb.com/ev-charging/products/car-charging/dc-fast-chargers",
    spec_src:
      "https://search.abb.com/library/Download.aspx?DocumentID=9AKK107680A7947&LanguageCode=en&DocumentPartId=&Action=Launch",
    has_discount: false,
    discount_value: 0,
    discount_code: "",
    certified_by: ["CSA", "EMC", "IEC", "NEC", "UL"],
    residential: false,
    commercial: true,
  },
};

export const DEFAULT_CHARGING_WINDOW: IChargingWindow = {
  start: 21,
  finish: 5,
  chargingApproach: "Even",
  type: "Level 2 - 7.7kW [Dual Port]",
  portKw: 7.7,
  charger: {
    id: "ClipperCreek_HCS_D40_",
    type: "Level 2 - 7.7kW [Dual Port]",
    ports: 2,
    portKw: 7.7,
    estimatedCost: 1484,
    chargerType: "level_2",
    imageUrl: "https://assets.zappyride.com/img/chargers/ClipperCreek%20HCS-D40.png",
    make: "Enphase",
    model: "HCS-D40",
    model_variant: "",
    price: 1484,
    price_src:
      "https://enphase.com/store/ev-chargers/commercial-ev-chargers/clippercreek-hcs-d40-dual-32-hardwired-25-ft-cables",
    is_estimated_price: false,
    form_factor: "Wall mounted",
    currents: ["AC"],
    amps: 32,
    amps_dc: 0,
    volts_ac: 240,
    volts_dc: 0,
    kw_ac: 7.7,
    kw_dc: 0,
    cord_length: 25,
    outlets: ["Hardwired"],
    connector_types: ["J1772"],
    num_of_ports: 2,
    load_balancing_protocol: [],
    ocpp_version: "",
    oscp_version: "",
    vgi_capable: false,
    enterprise_mgmt_capable: false,
    wifi: false,
    cellular_support: [],
    ethernet: false,
    bluetooth: false,
    credit_card_reader: false,
    nfc_reader: false,
    rfid_reader: false,
    warranty: 3,
    safe_outdoors: true,
    made_in_america: true,
    details_link: "https://enphase.com/store/clippercreek-hcs-d40p-dual-ev-charger-nema-14-50-plug-32",
    spec_src: "https://enphase.com/store/ev-chargers/clippercreek-ev-chargers",
    has_discount: false,
    discount_value: 0,
    discount_code: "",
    certified_by: ["cETL", "Energy Star", "ETL"],
    residential: true,
    commercial: true,
  },
};

// @ts-expect-error - Type is wrong
const DEFAULT_VEHICLE = {
  handle: "bollinger_b4_bev",
  make: "Bollinger",
  model: "B4",
  trim: "",
  fuel: "BEV",
  type: "Commercial",
  msrp: 158758,
  doors: 2,
  horsepower: 323,
  acceleration: 0,
  availability: "released",
  subtype: "Medium Duty",
  duty: "medium",
  gvwr: 15500,
  payload: 7325,
  images: [
    {
      alt: "Low Resolution View of the Bollinger B4",
      position: 0,
      url_full: "https://assets.zappyride.com/img/vehicles/generic/still-set-1280/bollinger_b4_bev/116.png",
      url_thumbnail:
        "https://assets.zappyride.com/img/vehicles/generic/still-set-1280-thumbnails/bollinger_b4_bev/116.th.png",
      legal_info_required: 0,
    },
  ],
  electric_vehicle_id: 2214,
  available_for_purchase_as_new: true,
  epa_id: 0,
  fuel_id: 1,
  model_year: 2025,
  form_factor: "cutaway_chassis",
  custom_price: "0",
  battery_capacity: 79,
  ac_charging_power: 19.2,
  dc_charging_power: 110,
  seats_min: 3,
  seats_max: 3,
  cargo_volume_min: 0,
  cargo_volume_max: 0,
  official_website_url: "https://bollingerstage.wpengine.com/bollinger-b4-electric-truck/",
  eligible_for_ca_rebate: "No",
  eligible_for_ca_hov: false,
  eligible_for_usa_tax_credit: false,
  usa_tax_credit_rebate: 0,
  san_joaquin_valley_ev_rebate: 0,
  electric_range: 185,
  total_range: 185,
  electric_efficiency: 0.4,
  is_commercial_only: true,
  is_base_trim: false,
  is_archtype: false,
  is_offroad: false,
  electric_efficiency_unit: "kwh per mile",
  weight_class: 4,
  curb_weight: 8175,
  forklift_class: null,
  lift_capacity: 0,
  is_estimated_price: false,
  fuel_types: ["electricity"],
  plug_types: ["CCS1"],
  battery_type: "lithium ion",
  is_overhead_charging_capable: false,
  overhead_charging_power: 0,
  is_induction_charging_capable: false,
  induction_charging_power: null,
  vgi_capable: null,
  maintenance_cost: 0.16,
  maintenance_cost_unit: "mile",
  is_estimated_maintenance: true,
  is_estimated_battery_capacity: false,
  is_estimated_gvwr: false,
  is_estimated_payload: false,
  is_estimated_plug_types: false,
  display_name: "B4",
  drivetrain: "Rear-Wheel Drive",
  fossil_fuel_efficiency: 0,
  additional_fields: [],
  video_reviews: [],
  equivalent_gas_vehicle: null,
  legal_info: null,
  baseline_annual_miles: null,
  linear_function_slope: null,
  linear_function_intercept: null,
} as Vehicle;

export const DEFAULT_VEHICLES_COUNT = 3;
export const DEFAULT_MILES_PER_WORKDAY = 50;
export const DEFAULT_HOURS_PER_WORKDAY = 8;
export const DEFAULT_WORKDAYS = [1, 2, 3, 4, 5];
export const DEFAULT_VEHICLE_CATEGORY = "On-Road";
export const DEFAULT_VEHICLE_TYPE = "Commercial";
export const DEFAULT_VEHICLE_SUBTYPE = "Medium Duty";
export const DEFAULT_VEHICLE_NAME = "";

export const DEFAULT_VEHICLE_SET_NO_VEHICLE: IVehicleSet = {
  idx: -1,
  vehicleCount: DEFAULT_VEHICLES_COUNT,
  milesPerWorkday: DEFAULT_MILES_PER_WORKDAY,
  hoursPerWorkday: DEFAULT_HOURS_PER_WORKDAY,
  workdays: DEFAULT_WORKDAYS,
  chargingWindows: [DEFAULT_CHARGING_WINDOW],
};

export const DEFAULT_VEHICLE_SET: IVehicleSet = {
  ...DEFAULT_VEHICLE_SET_NO_VEHICLE,
  vehicle_id: "bollinger_b4_bev",
  vehicle: DEFAULT_VEHICLE,
};

export const DEFAULT_CHARGER_SET: IChargerSet = {
  idx: -1,
  numChargersInstalled: 10,
  chargerLevelInstalled: "Level 2 - 7.7kW" as ICharger["type"],
  workdays: DEFAULT_WORKDAYS,
  workmonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  chargersPerTimePeriod: TIME_PERIODS.reduce(
    (sum, period) => ({
      ...sum,
      [period.key]: 5,
    }),
    {} as IChargerSetTimePeriod,
  ),

  vehicles: [],
  minimumRequired: 0,
  charger: {
    type: "Level 2 - 7.7kW" as ICharger["type"],
    ports: 1,
    portKw: 7.7,
    estimatedCost: 1065,
    chargerType: "level_2" as ICharger["chargerType"],
    imageUrl: "https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 7.7KW.png",
  } as ICharger,
};

export const DEFAULT_VEHICLE_SETS: IVehicleSet[] = import.meta.env.DEV ? [DEFAULT_VEHICLE_SET] : [];

const defaults: IApplicationInputs = {
  vehicleCount: 3,
  kwhDisplacedForLcfs: 100_000,
  gridIntensityInGramsCO2ePerMJ: ASSUMPTIONS.GRID_INTENSITY_PATHWAYS.values[0],
  estimationApproach: "Vehicles" as const,
  chargingFacilityType: "Custom" as TChargingFacilityType,
  dieselPrice: 3.1,
  gasolinePrice: 3.3,
  lpgPrice: 3.5,
  yearsOfOperation: 10,
  rateYears: times(10, (i) => new Date().getFullYear() + i) as TRateYear[],
  rateYear: new Date().getFullYear() as TRateYear,
  btmConstructionCost: 10000,
  minBtmEvseCost: 0,
  btmEvseCost: 0,
  customVehicles: [] as Vehicle[],
  customChargers: [] as ICharger[],
  isTaxExempt: "Yes" as const,
  isDAC: "No" as const,
  isPublic: "No" as const,
  replacesIceVehicle: "Yes" as const,
  organizationType: "private_school_districts" as IApplicationInputs["organizationType"],

  zipCode: "91754",

  vehicleSets: [],
  chargerSets: [],
  rateName: ERateName.TOU_EV_7,
  idealRateName: ERateName.TOU_EV_7,

  archetypes: [] as IArchetypeVehicle[],
  chargers: [] as ICharger[],
  incentives: [] as TCombinedIncentive[],
  vehicles: [] as Vehicle[],

  loading: true,
  isPrinting: false,

  setInput: (..._args: any[]) => null,
  setInputs: (_newInputs: IApplicationInputs) => null,
};

export default defaults;
