import { RateElementClassification } from "@bellawatt/electric-rate-engine";

export enum ERateName {
  TOU_EV_7 = "TOU_EV_7",
  TOU_EV_8 = "TOU_EV_8",
  TOU_EV_9 = "TOU_EV_9",
}

export enum ERateElementType {
  EnergyTimeOfUse = "EnergyTimeOfUse",
  BlockedTiersInDays = "BlockedTiersInDays",
  BlockedTiersInMonths = "BlockedTiersInMonths",
  FixedPerDay = "FixedPerDay",
  FixedPerMonth = "FixedPerMonth",
  MonthlyDemand = "MonthlyDemand",
  AnnualDemand = "AnnualDemand",
  MonthlyEnergy = "MonthlyEnergy",
  SurchargeAsPercent = "SurchargeAsPercent",
  HourlyEnergy = "HourlyEnergy",
  DemandTiersInMonths = "DemandTiersInMonths",
  DemandTimeOfUse = "DemandTimeOfUse",
  DemandPerDay = "DemandPerDay",
}

export interface IRateElement {
  name: string;
  rateElementType: ERateElementType;
  classification: RateElementClassification;
  rateComponents: IRateComponent[];
}

export interface IRateComponent {
  charge: number;
  months: number[];
  daysOfWeek: number[];
  hourStarts: number[];
  name:
    | "Summer Peak"
    | "Summer Mid-Peak"
    | "Summer Off-Peak"
    | "Winter Mid-Peak"
    | "Winter Off-Peak"
    | "Winter Super Off-Peak";
}

// Transmission - >50kV - used for transmission over large distances
// Primary - >2kV to 50kV - distribution to local transformers
// Secondary - Homes - <2kV
// * NOTE: Only doing Secondary for this project
export type TVoltage = "Primary" | "Secondary" | "Transmission";
