import { IApiCharger } from "@src/types/api_charging";
import { ICharger, IChargerV1 } from "@src/types/charging";

const createChargerType = (charger: IApiCharger): string => {
  const type = charger.currents.includes("DC") ? "DCFC" : "Level 2";
  const kw = type === "DCFC" ? charger.kw_dc : charger.kw_ac;
  const kwPerPort = (kw || 0) / (charger.num_of_ports || 1);
  const kwPerPortFixed = kwPerPort.toFixed(1).split(".0")[0];
  const ports =
    charger.num_of_ports === 2 ? "[Dual Port]" : charger.num_of_ports === 1 ? "" : `[${charger.num_of_ports} Port]`;
  return `${type} - ${kwPerPortFixed}kW ${ports}`;
};

export const transformCommercialChargers = (charger: IApiCharger): ICharger | null => {
  if (!charger.num_of_ports) return null;

  return {
    id: charger.charger_id,
    type: createChargerType(charger).trim() as IChargerV1["type"],
    ports: charger.num_of_ports,
    portKw: ((charger.currents.includes("DC") ? charger.kw_dc : charger.kw_ac) || 0) / charger.num_of_ports,
    estimatedCost: charger.price,
    chargerType: charger.currents.includes("DC") ? "dc_fast_charging" : "level_2",
    imageUrl: charger.img,
    make: charger.make,
    model: charger.model,
    model_variant: charger.model_variant,
    price: charger.price,
    price_src: charger.price_src,
    is_estimated_price: charger.is_estimated_price,
    form_factor: charger.form_factor,
    currents: charger.currents,
    amps: charger.amps,
    amps_dc: charger.amps_dc,
    volts_ac: charger.volts_ac,
    volts_dc: charger.volts_dc,
    kw_ac: charger.kw_ac,
    kw_dc: charger.kw_dc,
    cord_length: charger.cord_length,
    outlets: charger.outlets,
    connector_types: charger.connector_types,
    num_of_ports: charger.num_of_ports,
    load_balancing_protocol: charger.load_balancing_protocol,
    ocpp_version: charger.ocpp_version,
    oscp_version: charger.oscp_version,
    vgi_capable: charger.vgi_capable,
    enterprise_mgmt_capable: charger.enterprise_mgmt_capable,
    wifi: charger.wifi,
    cellular_support: charger.cellular_support,
    ethernet: charger.ethernet,
    bluetooth: charger.bluetooth,
    credit_card_reader: charger.credit_card_reader,
    nfc_reader: charger.nfc_reader,
    rfid_reader: charger.rfid_reader,
    warranty: charger.warranty,
    safe_outdoors: charger.safe_outdoors,
    made_in_america: charger.made_in_america,
    details_link: charger.details_link,
    spec_src: charger.spec_src,
    has_discount: charger.has_discount,
    discount_value: charger.discount_value,
    discount_code: charger.discount_code,
    certified_by: charger.certified_by,
    residential: charger.residential,
    commercial: charger.commercial,
  };
};
