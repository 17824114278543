import { useInputs } from "@src/hooks/useInputs";
import { colors, typography } from "@src/styles";
import React, { PropsWithChildren } from "react";
import { Link as RouterLink } from "react-router-dom";

const baseStyles = {
  fontSize: typography.links.primary,
  fontWeight: typography.weight.bolder,
  textTransform: "uppercase",
  textDecoration: "none",
  letterSpacing: 0,
  border: "none",
  backgroundColor: "transparent",
} as React.CSSProperties;

interface IProps extends PropsWithChildren {
  href: string;
  color?: string;
  className?: string;
  active?: boolean;
  applyBaseStyles?: boolean;
}

export const Link = ({ href, active, children, color, className, applyBaseStyles = true }: IProps) => {
  const { isPrinting, loading } = useInputs();
  if (isPrinting || loading) {
    // eslint-disable-next-line no-param-reassign
    href = "#";
  }

  const isExternalLink = href.includes("http://") || href.includes("https://");

  if (isExternalLink) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        aria-disabled={isPrinting || loading}
      >
        {children}
      </a>
    );
  } else {
    return (
      <RouterLink
        to={href}
        style={
          applyBaseStyles
            ? {
                ...baseStyles,
                color: active ? colors.brand.darkBlue : color || colors.text.base,
              }
            : undefined
        }
        className={className}
        aria-disabled={isPrinting || loading}
      >
        {children}
      </RouterLink>
    );
  }
};
