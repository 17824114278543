import { FaFacebook, FaInstagram, FaLinkedin, FaXTwitter, FaYoutube } from "react-icons/fa6";
import Logo from "@src/assets/images/sce-logo-footer.svg?react";
import { Link } from "@src/layout/Links";
import { Fragment } from "react/jsx-runtime";
import { memo } from "react";

const PageFooter = memo(() => {
  return (
    <div className="container-fluid d-print-none">
      <div className="row bg-black">
        <div className="col">
          <div className="row border-bottom border-4 border-white">
            <div className="col text-center py-5">
              <div className="text-uppercase text-white bold fs-5 py-3">Connect with us</div>
              <div className="d-flex flex-row gap-5 align-items-center justify-content-center">
                <Link href="http://www.facebook.com/SCE" className="fs-1 text-white">
                  <FaFacebook title="Facebook" />
                </Link>
                <Link href="http://www.youtube.com/sce" className="fs-1 text-white">
                  <FaYoutube title="Youtube" />
                </Link>
                <Link href="http://www.twitter.com/SCE" className="fs-1 text-white">
                  <FaXTwitter title="XTwitter" />
                </Link>
                <Link href="http://www.linkedin.com/company/sce" className="fs-1 text-white">
                  <FaLinkedin title="Linkedin" />
                </Link>
                <Link href="http://www.instagram.com/SCE" className="fs-1 text-white">
                  <FaInstagram title="Instagram" />
                </Link>
              </div>
            </div>
          </div>
          <div className="row border-bottom border-4 border-white py-5">
            <div className="col-12 col-md-auto pb-4 d-flex flex-column align-items-center justify-content-center">
              <a href="https://www.sce.com" target="_blank" rel="noopener noreferrer" className="text-white">
                <Logo title="SCE" />
              </a>
            </div>
            {Object.keys(LINKS).map((key) => (
              <div key={key} className="col">
                <p className="fs-5 text-white text-uppercase bold pb-3">{key}</p>
                {LINKS[key as keyof typeof LINKS].map((link) => (
                  <Link key={link.href} href={link.href} className="text-white no-underline d-block py-1">
                    <small>{link.text}</small>
                  </Link>
                ))}
              </div>
            ))}
          </div>
          <div className="row py-5 mb-5">
            <div className="col">
              <div className="d-flex flex-row flex-wrap align-items-center justify-content-center gap-3">
                {BOTTOM_LINKS.map((link, i) => {
                  return (
                    <Fragment key={link.href}>
                      <Link key={link.href} href={link.href} className="text-white no-underline bold">
                        <small>{link.text}</small>
                      </Link>
                      {i === BOTTOM_LINKS.length - 1 ? null : <span className="px-1 text-white">|</span>}
                    </Fragment>
                  );
                })}
              </div>
              <div className="row">
                <div className="col text-center text-white pt-1" style={{ fontSize: "10px" }}>
                  © {new Date().getFullYear()} Southern California Edison
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const LINKS = {
  "Quick Links": [
    {
      text: "Pay Your Bill",
      href: "https://www.sce.com/customer-service/billing-payment/pay-my-bill",
    },
    {
      text: "View Balance & Usage",
      href: "https://www.sce.com/mysce/myaccount",
    },
    {
      text: "Start/Stop Service",
      href: "https://www.sce.com/customer-service/movecenter",
    },
    {
      text: "Preference Center",
      href: "https://www.sce.com/customer-service/self-service-forms/update-your-contact-information",
    },
    {
      text: "Rebates for Your Home",
      href: "https://www.sce.com/residential/rebates-savings/rebates",
    },
    {
      text: "Report an Outage",
      href: "https://www.sce.com/outage-center/report-an-outage",
    },
    {
      text: "Help Center",
      href: "https://www.sce.com/helpcenter",
    },
  ],
  "Your Home": [
    {
      text: "Payment Assistance",
      href: "https://www.sce.com/customer-service/billing-payment/financial-assistance",
    },
    {
      text: "Rebates & Incentives",
      href: "https://www.sce.com/residential/rebates-savings/rebates",
    },
    {
      text: "Rate Plans",
      href: "https://www.sce.com/residential/rates",
    },
    {
      text: "Solar Power",
      href: "https://www.sce.com/residential/generating-your-own-power/solar-power",
    },
    {
      text: "Electric Vehicles",
      href: "https://www.sce.com/residential/ev-overview",
    },
    {
      text: "Home Efficiency Guide",
      href: "https://www.sce.com/residential/home-energy-guide",
    },
  ],
  "Your Business": [
    {
      text: "Why Choose Electric",
      href: "https://www.sce.com/home-energy-guide/electric-home",
    },
    {
      text: "Savings & Incentives",
      href: "https://www.sce.com/business/savings-incentives",
    },
    {
      text: "Rate Plans",
      href: "https://www.sce.com/business/rates",
    },
    {
      text: "Solar Power",
      href: "https://www.sce.com/business/generating-your-own-power/solar-power-for-business",
    },
    {
      text: "Electric Vehicles",
      href: "https://www.sce.com/evbusiness/overview",
    },
    {
      text: "Consulting Services",
      href: "https://www.sce.com/business/consulting-services",
    },
    {
      text: "Small Business Resources",
      href: "https://www.sce.com/business/Small-Business-Resources",
    },
  ],
  "About Us": [
    {
      text: "About Us",
      href: "https://www.sce.com/about-us",
    },
    {
      text: "Accessibility",
      href: "https://www.sce.com/customer-service/faqs/service/Accessibility",
    },
    {
      text: "Careers",
      href: "https://www.edisoncareers.com/",
    },
    {
      text: "Energy Procurement",
      href: "https://www.sce.com/procurement",
    },
    {
      text: "Energy Education Centers",
      href: "https://www.sce.com/residential/energy-education-centers",
    },
    {
      text: "Regulatory",
      href: "https://www.sce.com/regulatory",
    },
    {
      text: "Catalina Island",
      href: "https://www.sce.com/customer-service/faqs/catalina-island",
    },
  ],
  Newsroom: [
    {
      text: "Edison International",
      href: "https://www.edison.com/",
    },
    {
      text: "Energized by Edison",
      href: "https://energized.edison.com/",
    },
    {
      text: "Newsroom",
      href: "https://newsroom.edison.com/",
    },
  ],
};

const BOTTOM_LINKS = [
  {
    text: "Terms of Use",
    href: "https://www.sce.com/tnc",
  },
  {
    text: "Privacy Notice",
    href: "https://www.sce.com/privacy",
  },
  {
    text: "CCPA Privacy Policy",
    href: "https://www.sce.com/privacy/california-consumer-privacy-act-policy",
  },
  {
    text: "Personal Information Request",
    href: "https://www.sce.com/california-consumer-privacy-act-information-request",
  },
  {
    text: "Copyright Act Policy",
    href: "https://www.sce.com/privacy/copyright-act-policy",
  },
  {
    text: "Sitemap",
    href: "https://www.sce.com/sitemap",
  },
  {
    text: "Browser Requirements",
    href: "https://www.sce.com/customer-service/faqs/service/browser-requirements",
  },
];

PageFooter.displayName = "PageFooter";

export default PageFooter;
