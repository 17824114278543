import { ICharger } from "@src/types/charging";
import { IVehicleSet } from "@src/types/sets";
import calcBatterySchedule from "./calcBatterySchedule/calcBatterySchedule";
import { isEmpty } from "lodash";

/**
 * Use when seasonality is NOT important.
 *
 * Used in most Fleets graphs.
 *
 * @returns number[][]
 */
const calcWeeklyLoadProfiles = (chargers: ICharger[], vehicleSets: IVehicleSet[]) => {
  const profile = vehicleSets.map((vehicleSet) => {
    const batterySchedule = calcBatterySchedule(
      isEmpty(chargers)
        ? (vehicleSet.chargingWindows.map((win) => win.charger).filter(Boolean) as ICharger[])
        : chargers,
      vehicleSet,
    ).weeklyBatterySchedule;

    return batterySchedule.map((schedule) => schedule.kwhPowerDrawn);
  });
  return profile;
};

export default calcWeeklyLoadProfiles;
