const colors = {
  primary: "#052037",
  secondary: "#FED141",
  white: "#fff",
  blueGrey: "#4a5e6e",
  lightGrey: "#717171",
  darkGrey: "#333333",
  background: "#f8f8f8",
  blue: {
    darkest: "#031A2D",
    darker: "#042037",
    base: "#00A9E0",
    lighter: "#0dcaf0",
    lightest: "#B8D7ED",
  },
  orange: {
    darkest: "#BF7E2A",
    darker: "#E59902",
    base: "#FBB04C",
    lighter: "#FFD992",
    lightest: "#FEEDC7",
  },
  grey: {
    darkest: "#111111",
    darker: "#333333",
    base: "#53565A",
    lighter: "#CCCCCC",
    lightest: "#F5F5F5",
  },
  green: {
    darkest: "#749D25",
    darker: "#97B068",
    base: "#00664F",
    lighter: "#247C68",
    lightest: "#78BE20",
  },
  red: {
    darkest: "#722257",
  },
  text: {
    base: "#333333",
  },
  brand: {
    orange: "#FBBB36",
    darkBlue: "#07679B",
    blue: "#0089C4",
  },
  chart: {
    yellow: "#FED141",
    darkBlue: "#052037",
    green: "#00664F",
    orange: "#FBB04C",
    lightBlue: "#00A9E0",
    blue: "#00A9E0",
    grey: "#53565A",
  },
};

export default colors;
