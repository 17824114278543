import groupByChargers from "@src/calcs/groupByChargers";
import { ICharger, TChargingFacilityType } from "@src/types/charging";
import { IChargerSet, IVehicleSet } from "@src/types/sets";
import { sumBy } from "lodash";
import { useCallback, useMemo } from "react";

export const useComputeRateInfo = () => {
  const fromVehicleSets = useCallback(
    ({ vehicleSets, chargers: _chargers }: { vehicleSets: IVehicleSet[]; chargers: ICharger[] }) => {
      // const weeklyLoadProfile = calcTotalWeeklyLoadProfile(chargers, vehicleSets);
      // const roundedKwh = Math.round(calcTotalKwh(chargers, vehicleSets) / 10000) * 10000;
      // const idealRateName = calcIdealBevRateName(weeklyLoadProfile);

      const chargingSets = groupByChargers(vehicleSets);
      const minBtmEvseCost = sumBy(
        (chargingSets ?? []) as IChargerSet[],
        ({ charger, minimumRequired }: { charger?: ICharger; minimumRequired: number }) =>
          (charger?.estimatedCost ?? 0) * minimumRequired,
      );

      return {
        // kwhDisplacedForLcfs: roundedKwh,
        // rateName: idealRateName,
        minBtmEvseCost: minBtmEvseCost,
        // rateComparisonVehiclesBevRateName: idealRateName,`
      };
    },
    [],
  );

  const fromChargerSets = useCallback(
    ({
      chargerSets: _chargerSets,
      chargingFacilityType: _chargingFacilityType,
    }: {
      chargerSets: any[];
      chargingFacilityType: TChargingFacilityType;
    }) => {
      // const weeklyLoadProfile = calcTotalChargerSetLoadProfile({
      //   chargerSets,
      //   timeRange: "weekly",
      //   facilityType: chargingFacilityType,
      // });

      // const idealRateName = calcIdealBevRateName(weeklyLoadProfile);
      return {
        // rateComparisonChargersBevRateName: weeklyLoadProfile,
      };
    },
    [],
  );

  return useMemo(
    () => ({
      vehicleSets: fromVehicleSets,
      chargerSets: fromChargerSets,
    }),
    [fromChargerSets, fromVehicleSets],
  );
};
