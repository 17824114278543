const typography = {
  headers: ["", "60px", "32px", "24px", "20px", "16px", "14px"],
  cta: "16px",
  menu: "14px",
  label: "14px",
  input: "14px",
  statLabel: "12px",
  body: {
    primary: "24px",
    secondary: "20px",
    tertiary: "12px",
  },
  links: {
    primary: "16px",
    secondary: "12px",
  },
  weight: {
    normal: 400,
    bold: 500,
    bolder: "bold",
  },
};

export default typography;
