import { IArchetypeVehicle, Vehicle } from "@src/types/vehicle";

// https://jdpa.atlassian.net/wiki/spaces/ZUA/pages/131536060/archetype-vehicles
/**
 * Matching /commercial/vehicles to Vehicles in /archetype-vehicles
 * While it might be expected that form_factor and subtype are identical in both endpoints, some translation is required for passenger vehicles. For passenger vehicles form factors are stored in /archetype-vehicles as the subtype.
 *
 * This means when matching passenger vehicles from /commercial/vehicles to /archetype/vehicles use the following rule…
 *
 * IF /commercial/vehicles type=”Passenger” THEN match
 * /commercial/vehicles subtype to /archetype-vehicles form_factor
 *
 * ELSE match…
 * /commercial/vehicles form_factor = /archetype-vehicles form_factor AND
 * commercial/vehicles subtype = /archetype-vehicles subtype
 *
 * ---------------------------------------------------------------
 *
 * Bellawatt NOTE: archetypes and vehicles have the same handles so that works too?
 */

const prep = (str: string) => str?.toLowerCase()?.trim()?.replaceAll("-", "_").replaceAll(" ", "_");
const prepFormFactor = (str: string) => {
  const ff = prep(str);
  if (["minivan", "cutaway_chassis"].includes(ff)) {
    // JDP has no archetypes with "Minivan" or "Cutaway_Chassis" as a form factor, "Cutaway_Chassis" is used by Ford e-transit vans
    return "cargo_van";
  } else {
    return ff;
  }
};

// category_formFactor_subtype
const prepVehicleHandle = (vehicle: Vehicle) => {
  return `${prep(vehicle.category)}_${prepFormFactor(vehicle.formFactor)}_${[prep(vehicle.type)]}`;
};

export const findArchetype = (archetypes: IArchetypeVehicle[], vehicle: Vehicle | undefined | null) => {
  if (!vehicle || !archetypes.length) return null;

  // JDP has tractors with duty = "medium" and subtype = "Light Duty" so we can't match on subtype
  const isSameSubType = (archetype: IArchetypeVehicle) => {
    return vehicle.formFactor === "tractor" || prep(archetype.subtype) === prep(vehicle.subtype);
  };

  let _findByVehicleIdMatchesArchetypeHandle: IArchetypeVehicle | null | undefined;
  const findByVehicleIdMatchesArchetypeHandle = () => {
    if (typeof _findByVehicleIdMatchesArchetypeHandle === "undefined") {
      _findByVehicleIdMatchesArchetypeHandle =
        archetypes.find((a) => [prep(vehicle.id), prepVehicleHandle(vehicle)].includes(prep(a.handle))) || null;
    }
    return _findByVehicleIdMatchesArchetypeHandle;
  };

  let _findByVehicleSubTypeAndFormFactorMatchesArchetypeSubTypeAndFormFactor: IArchetypeVehicle | null | undefined;
  const findByVehicleSubTypeAndFormFactorMatchesArchetypeSubTypeAndFormFactor = () => {
    if (typeof _findByVehicleSubTypeAndFormFactorMatchesArchetypeSubTypeAndFormFactor === "undefined") {
      _findByVehicleSubTypeAndFormFactorMatchesArchetypeSubTypeAndFormFactor =
        archetypes.find(
          (a) => prepFormFactor(a.formFactor) === prepFormFactor(vehicle.formFactor) && isSameSubType(a),
        ) || null;
    }
    return _findByVehicleSubTypeAndFormFactorMatchesArchetypeSubTypeAndFormFactor;
  };

  let _findByVehicleSubyTypeMatchesArchetypeFormFactor: IArchetypeVehicle | null | undefined;
  const findByVehicleSubyTypeMatchesArchetypeFormFactor = () => {
    if (typeof _findByVehicleSubyTypeMatchesArchetypeFormFactor === "undefined") {
      _findByVehicleSubyTypeMatchesArchetypeFormFactor =
        archetypes.find((a) => prepFormFactor(a.formFactor) === prep(vehicle.subtype)) || null;
    }
    return _findByVehicleSubyTypeMatchesArchetypeFormFactor;
  };

  // Crossover vehicles match Crossover archetypes
  // Ex. BMW X5 xDrive50e - BMW_X5_xDrive50e_PHEV_2025
  let _findByVehicleFormFactorMatchesArchetypeFormFactor: IArchetypeVehicle | null | undefined;
  const findByVehicleFormFactorMatchesArchetypeFormFactor = () => {
    if (typeof _findByVehicleFormFactorMatchesArchetypeFormFactor === "undefined") {
      _findByVehicleFormFactorMatchesArchetypeFormFactor =
        archetypes.find((a) => prepFormFactor(a.formFactor) === prepFormFactor(vehicle.formFactor)) || null;
    }
    return _findByVehicleFormFactorMatchesArchetypeFormFactor;
  };

  return (
    (prep(vehicle.type) === "passenger"
      ? findByVehicleSubyTypeMatchesArchetypeFormFactor()
      : findByVehicleSubTypeAndFormFactorMatchesArchetypeSubTypeAndFormFactor()) || // start with these two if its a passenger vehicle
    findByVehicleIdMatchesArchetypeHandle() ||
    findByVehicleSubTypeAndFormFactorMatchesArchetypeSubTypeAndFormFactor() || // may have been tried for passenger vehicle already but the result is memoized
    findByVehicleFormFactorMatchesArchetypeFormFactor()
  );
};
