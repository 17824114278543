import A from "@src/data/ASSUMPTIONS";
import getCharger from "@src/data/getCharger";
import { ICharger, IPlugSchedule } from "@src/types/charging";
import { IVehicleSet } from "@src/types/sets";
import { isEmpty, times } from "lodash";
import isHourInUse from "./isHourInUse";

const calcPlugSchedule = (chargers: ICharger[], vehicleSet: IVehicleSet): IPlugSchedule[] => {
  const rawPlugSchedule = A.HOUR_OF_DAY.map((hour) =>
    vehicleSet.chargingWindows.filter((chargingWindow) =>
      isHourInUse(hour, chargingWindow.start, chargingWindow.finish),
    ),
  );

  if (isEmpty(rawPlugSchedule) || isEmpty(chargers)) {
    return times(rawPlugSchedule.length).map((_i) => ({ chargingApproach: null, portKw: 0 }));
  }

  return rawPlugSchedule.map((plug) =>
    plug.length >= 1
      ? {
          // If two chargers have overlapping hours, default to the first charger input and handle alarm state elesewhere
          chargingApproach: plug[0].chargingApproach,
          portKw: getCharger(chargers, plug[0].type as ICharger["type"])?.portKw || null,
        }
      : // : { chargingApproach: null, portKw: null },
        { chargingApproach: null, portKw: null },
  );
};

export default calcPlugSchedule;
