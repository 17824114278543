import times from "lodash/times";
import isHourInUse from "./isHourInUse";
import { IChargerSet, IVehicleChargingUsage, IVehicleSet } from "@src/types/sets";

const groupByChargers = (vehicleSets: IVehicleSet[]) => {
  const chargingSets = {} as Record<string, Partial<IChargerSet>>;

  vehicleSets.forEach(({ chargingWindows, ...vehicleSet }) => {
    chargingWindows.forEach((chargingWindow) => {
      const vehicleChargingUsage: IVehicleChargingUsage = {
        ...vehicleSet,
        chargingWindows: [chargingWindow],
        usage: times(24, (hour) =>
          isHourInUse(hour, chargingWindow.start, chargingWindow.finish) ? vehicleSet.vehicleCount : 0,
        ),
      };

      chargingSets[chargingWindow.type] = {
        ...chargingWindow,
        ...chargingWindow.charger,
        vehicles: chargingSets[chargingWindow.type]
          ? [...(chargingSets[chargingWindow.type]?.vehicles ?? []), vehicleChargingUsage]
          : [vehicleChargingUsage],
      };
    });
  });

  Object.keys(chargingSets).forEach((key) => {
    const summedHours = (chargingSets[key]?.vehicles ?? []).reduce(
      (sum, { usage }) => {
        return sum.map((val, i) => val + usage[i]);
      },
      times(24, () => 0),
    );

    chargingSets[key].minimumRequired = chargingSets[key].charger?.ports
      ? Math.ceil(Math.max(...summedHours) / chargingSets[key].charger?.ports)
      : 0;
  });

  return Object.values(chargingSets);
};

export default groupByChargers;
