import { EApiPath } from "@src/api/fetchRecords";
import { transformCommercialChargers } from "@src/api/transform/transformCommercialChargers";
import { useApi } from "@src/api/useApi";
import { useInputs } from "@src/hooks/useInputs";
import { useMemo } from "react";

export const useCommercialChargers = (skipinitialFetch?: boolean) => {
  const { zipCode } = useInputs();
  const options = useMemo(
    () => ({
      skipinitialFetch,
      method: "GET" as const,
      resultsKey: "chargers",
      params: { zipCode },
      uniqByKey: "type",
    }),
    [skipinitialFetch, zipCode],
  );
  return useApi(EApiPath.COMMERCIAL_CHARGERS, transformCommercialChargers, options);
};
