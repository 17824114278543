import Loading from "@src/components/loading/Loading";
import TalkToAnSCESpecialist from "@src/components/TalkToAnSCESpecialist";
import { useInputs } from "@src/hooks/useInputs";
import Header from "@src/layout/Header";
import PageFooter from "@src/layout/PageFooter";
import { PropsWithChildren } from "react";
import Container from "react-bootstrap/Container";

interface IProps extends PropsWithChildren {
  page: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  isBottomPadded?: boolean;
}

const PageLayout: React.FC<IProps> = ({ children, page, header, footer, isBottomPadded = true }) => {
  const { isPrinting, loading } = useInputs();

  return (
    <main>
      <div
        className="d-none d-sm-flex flex-row align-items-center gap-3 position-fixed d-print-none"
        style={{
          left: 0,
          top: "35%",
          zIndex: 100,
          transform: "rotate(90deg)",
          transformOrigin: "bottom left",
        }}
      >
        <div>
          <TalkToAnSCESpecialist />
        </div>
      </div>
      <Header page={page} />
      <Container fluid className={`p-0 ${isBottomPadded ? "mb-5 pb-5" : ""}`}>
        {header}
        <Container fluid style={{ maxWidth: "1400px" }}>
          <div style={{ visibility: isPrinting || loading ? "visible" : "hidden" }} className="d-print-none">
            <Loading />
          </div>
          {children}
        </Container>
        {footer}
      </Container>
      <PageFooter />
    </main>
  );
};

export default PageLayout;
