import { EApiPath } from "@src/api/fetchRecords";
import { transformCommercialVehicle } from "@src/api/transform/transformCommercialVehicle";
import { useApi } from "@src/api/useApi";
import { useArchetypeVehicles } from "@src/api/useArchetypeVehicles";
import { useInputs } from "@src/hooks/useInputs";
import { ApiCommercialVehicle } from "@src/types/api_vehicle";
import { useCallback, useMemo } from "react";

export const useCommercialVehicles = (skipinitialFetch?: boolean) => {
  const { zipCode } = useInputs();

  const { items: archetypes } = useArchetypeVehicles(false);

  const transform = useCallback((v: ApiCommercialVehicle) => transformCommercialVehicle(v, archetypes), [archetypes]);

  const options = useMemo(
    () => ({
      skipinitialFetch,
      skipRefresh: skipinitialFetch,
      resultsKey: "vehicles",
      params: {
        postcode: zipCode,
      },
    }),
    [skipinitialFetch, zipCode],
  );
  return useApi(EApiPath.COMMERCIAL_VEHICLES, transform, options);
};
