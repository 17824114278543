import { TChargingFacilityType } from "@src/types/charging";
import { TTimeRange } from "@src/types/datetime";
import { IChargerSet } from "@src/types/sets";
import calcChargerSetLoadProfile from "./calcChargerSetLoadProfile";

const calcTotalChargerSetLoadProfile = ({
  chargerSets,
  timeRange,
  facilityType,
}: {
  chargerSets: IChargerSet[];
  timeRange: TTimeRange;
  facilityType: TChargingFacilityType;
}) => {
  const schedules = chargerSets.map((set) => calcChargerSetLoadProfile(set, { timeRange, facilityType }));
  const profile = !schedules.length
    ? Array((timeRange === "yearly" ? 365 : 7) * 24).fill(0)
    : schedules.reduce((sums, current) => sums.map((value, i) => value + current[i])); // TODO: Figure out what this returns and add an initial value to the ".reduce"

  return profile;
};

export default calcTotalChargerSetLoadProfile;
