const HeaderText = ({
  children,
  className,
  style,
  variant = "h2",
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
  variant?: "h1" | "h2";
}) => {
  switch (variant) {
    case "h1":
      return (
        <h1 className={`${className || "d-block m-0 fs-4 text-center fst-normal p-5"}`} style={style}>
          {children}
        </h1>
      );

    default:
      return (
        <h2 className={`${className || "d-block m-0 fs-4 text-center fst-normal p-5"}`} style={style}>
          {children}
        </h2>
      );
  }
};

export default HeaderText;
