import { ICharger } from "@src/types/charging";
import { IChargerSet } from "@src/types/sets";
import { useCallback } from "react";

// Assigns chargers to each Charger Set
export const useComputeChargerSets = () => {
  return useCallback((chargers: ICharger[], newChargerSets: IChargerSet[]) => {
    return newChargerSets.map((chargerSet) => {
      const charger = chargers.find((c) => c.type === chargerSet.charger?.type);
      if (!charger) return chargerSet;

      return {
        ...chargerSet,
        charger,
      };
    });
  }, []);
};
