import dcfcLoadProfile from "./dcfc";
import workplaceLoadProfile from "./workplace";
import multifamilyLoadProfile from "./multifamily";
import transitLoadProfile from "./transit";
import mediumDutyLoadProfile from "./mediumDuty";
import { TChargingFacilityType } from "@src/types/charging";
import { TLoadProfile } from "@src/types/profile";

const loadProfiles = {
  DCFC: dcfcLoadProfile,
  Workplace: workplaceLoadProfile,
  Multifamily: multifamilyLoadProfile,
  Transit: transitLoadProfile,
  "Medium Duty": mediumDutyLoadProfile,
} as Record<TChargingFacilityType, TLoadProfile>;

export default loadProfiles;
