import { ICharger } from "@src/types/charging";
import calcWeeklyLoadProfiles from "./calcWeeklyLoadProfiles";
import times from "lodash/times";
import { IVehicleSet } from "@src/types/sets";

/**
 * Use when seasonality is NOT important.
 *
 * @returns  number[]
 */
const calcTotalWeeklyLoadProfile = (chargers: ICharger[], vehicleSets: IVehicleSet[]) => {
  const weeklyLoadProfiles = calcWeeklyLoadProfiles(chargers, vehicleSets);

  const totalWeeklyLoadProfile = times(24 * 7, (i) => {
    let hourlyLoad = 0;
    for (const element of weeklyLoadProfiles) {
      hourlyLoad += element[i];
    }
    return hourlyLoad;
  });

  return totalWeeklyLoadProfile;
};

export default calcTotalWeeklyLoadProfile;
