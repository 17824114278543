import times from "lodash/times";
import { IProfileDateTime } from "@src/types/profile";
import dayjs from "dayjs";

const hourlyDates: Record<number, IProfileDateTime[]> = {};

const startOfYear = dayjs().month(0).date(1).hour(0).minute(0).second(1);

export const generateHoursOfYear = (year: number, isFromStartOfYear = false) => {
  let profileTime = isFromStartOfYear ? startOfYear.year(year) : dayjs().year(year);

  return times(profileTime.isLeapYear() ? 8784 : 8760, (hourOfYear) => {
    const val: IProfileDateTime = {
      month: profileTime.month(),
      dayOfWeek: profileTime.day(),
      hourStart: profileTime.hour(),
      date: profileTime.format("YYYY-MM-DD"),
      hourOfYear,
    };

    profileTime = profileTime.add(1, "hour");
    return val;
  });
};

export const getHoursOfYear = (year: number, isFromStartOfYear = false): IProfileDateTime[] => {
  if (!hourlyDates[year]) {
    hourlyDates[year] = generateHoursOfYear(year, isFromStartOfYear);
  }

  return hourlyDates[year];
};

export const getHoursOfWeek = () => {
  return times(24 * 7, (i) => ({
    hourStart: i % 24,
    dayOfWeek: Math.floor(i / 24) === 6 ? 0 : Math.floor(i / 24) + 1,
  }));
};
