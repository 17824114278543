import HeaderText from "@src/layout/HeaderText";
import { Link } from "@src/layout/Links";
import PageLayout from "@src/layout/PageLayout";

const NotFoundPage = () => {
  return (
    <PageLayout page="Not Found">
      <div className="row mb-5 pb-5">
        <div className="col-0 col-lg-3">&nbsp;</div>
        <div className="col-12 col-lg-6">
          <div className="text-white bg-success text-white mb-4">
            <HeaderText variant="h1">Page Not Found</HeaderText>
          </div>
          <p>We&apos;re sorry, but you&apos;ve requested a page that cannot be found.</p>
          <p>
            If you&apos;ve reached this page by selecting a bookmark that worked previously, it&apos;s likely the page
            moved to a new location because of our recent redesign.
          </p>
          <div>
            <Link href={window.location.hostname} className="btn btn-link ps-0">
              Navigate Home
            </Link>
          </div>
        </div>
        <div className="col-0 col-lg-3">&nbsp;</div>
      </div>
    </PageLayout>
  );
};

export default NotFoundPage;
